import React, { useState, useEffect } from 'react';
import { Card, Button, Collapse, Table, InputGroup, FormControl, Pagination } from 'react-bootstrap';
import { useAuth } from '../utils/auth';

const OverviewToday = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage] = useState(10);
  const [totals, setTotals] = useState({ todayTotal: 0, monthTotal: 0, yearTotal: 0 });
  const [isOpenOrds, setIsOpenOrds] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useAuth();

  useEffect(() => {
    const token = localStorage.getItem('userToken');
    if (token) {
      fetchOrderHistory(token);
    }
  }, []);

  const fetchOrderHistory = async (token) => {
    setIsLoading(true);
    const domain = localStorage.getItem('domain');
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/adminords`, {
        method: 'POST',
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ domain }),
      });
      if (!response.ok) {
        throw new Error('Failed to fetch order history');
      }
      const responseData = await response.json();
      if (responseData.status === 'success') {
        setOrders(responseData.orders);
        setFilteredOrders(responseData.orders);
        setTotals({
          todayTotal: Number(responseData.todayTotal) || 0,
          monthTotal: Number(responseData.monthTotal) || 0,
          yearTotal: Number(responseData.yearTotal) || 0,
          allUsers: responseData.allUsers,
          verifiedUsers: responseData.verifiedUsers,
          blockedUsers: responseData.blockedUsers,
        });
      } else {
        setOrders([]);
        setFilteredOrders([]);
        setTotals({
          todayTotal: 0,
          monthTotal: 0,
          yearTotal: 0,
        });
      }
    } catch (error) {
      console.error('Error fetching order history:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    if (value) {
      setFilteredOrders(orders.filter(order => 
        order.orderId.toLowerCase().includes(value.toLowerCase()) ||
        order.email.toLowerCase().includes(value.toLowerCase()) ||
        order.service.toLowerCase().includes(value.toLowerCase())
      ));
    } else {
      setFilteredOrders(orders);
    }
    setCurrentPage(1); // Reset to first page when searching
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = filteredOrders.slice(indexOfFirstOrder, indexOfLastOrder);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredOrders.length / ordersPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <Card className="mb-1">
      <Card.Header style={{ backgroundColor: 'green', textAlign: 'center', fontWeight: 'bold' }}>
        <Button variant="link" onClick={() => setIsOpenOrds(!isOpenOrds)} aria-controls="collapse-card-3" aria-expanded={isOpenOrds} style={{ color: 'white' }}>
          Overview (Today)
        </Button>
      </Card.Header>
      <Collapse in={isOpenOrds}>
        <Card.Body>
          {isLoading && (
            <div className="text-center">Loading...</div>
          )}
          {!isLoading && (
            <div>
     <div className="row mb-3">









     <div className="col-md-4">
              <Card className="mb-2">
              <center>
                <Card.Body><h3>{totals.allUsers}</h3>Site Users</Card.Body>
                </center>
              </Card>
            </div>

            <div className="col-md-4">
              <Card className="mb-2">
              <center>
                <Card.Body><h3>{totals.verifiedUsers}</h3>Verified & Active</Card.Body>
                </center>
              </Card>
            </div>


            
            <div className="col-md-4">
              <Card className="mb-2">
                <center>
                <Card.Body><h3>{totals.blockedUsers}</h3>Blocked Users</Card.Body>
                </center>
              </Card>
            </div>

<hr />











  <div className="col-md-4">
    <Card className="mb-2">
      <center>
        <Card.Body>
          <h3>
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'NGN',
              minimumFractionDigits: 2
            }).format(totals.todayTotal)}
          </h3>
          Today's Sales
        </Card.Body>
      </center>
    </Card>
  </div>
  <div className="col-md-4">
    <Card className="mb-2">
      <center>
        <Card.Body>
          <h3>
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'NGN',
              minimumFractionDigits: 2
            }).format(totals.monthTotal)}
          </h3>
          This Month's Sales
        </Card.Body>
      </center>
    </Card>
  </div>
  <div className="col-md-4">
    <Card className="mb-2">
      <center>
        <Card.Body>
          <h3>
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'NGN',
              minimumFractionDigits: 2
            }).format(totals.yearTotal)}
          </h3>
          This Year's Sales
        </Card.Body>
      </center>
    </Card>
  </div>
</div>
              <InputGroup className="mb-3">
                <FormControl
                  placeholder="Search Orders"
                  aria-label="Search Orders"
                  value={searchTerm}
                  onChange={handleSearch}
                />
              </InputGroup>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Order ID</th>
                    <th>Service</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {currentOrders.map((order) => (
                    <tr key={order.orderId}>
                      <td>ID: {order.orderId}<br /><small>{order.email}</small></td>
                      <td><b>{order.service}</b><br /><small>{order.date}<br />{order.phoneNumber}</small></td>
                      <td><small>₦{order.amount}<br />
                      OB: {order.oldBal}<br />
                      NB: {order.newBal}
                      </small></td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Pagination>
                {pageNumbers.map(number => (
                  <Pagination.Item
                    key={number}
                    active={number === currentPage}
                    onClick={() => handlePageChange(number)}
                  >
                    {number}
                  </Pagination.Item>
                ))}
              </Pagination>
            </div>
          )}
        </Card.Body>
      </Collapse>
    </Card>
  );
};

export default OverviewToday;